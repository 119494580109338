import RailsImg from '../assets/images/rails-1.jpg';
import CssImg from '../assets/images/css-in-css.jpeg';
import BizImg from '../assets/images/start-biz.jpeg';
import GitImg from '../assets/images/git-cheat.jpeg';
import FrontEndImg from '../assets/images/frontend-concepts.jpg'
import FrontEndOrBackend from '../assets/images/frontend-or-backend.jpg'
import CreateReactApp from '../assets/images/create-react-app.jpeg'
import OnboardSoftware from '../assets/images/onboard-software-engineer.webp'

const ARTICLES = [
  [
    {
      image: OnboardSoftware,
      href:
        'https://leaddev.com/hiring/7-steps-to-navigate-onboarding-as-a-software-engineer',
      title: '7 steps to navigate onboarding as a software engineer',
      description: `
      Joining a new team can be both exciting and overwhelming. It’s natural to want to show your skills and how you can contribute to your new team. 

      While onboarding documentation can be helpful, true integration goes beyond just checking off tasks. It’s a journey of building meaningful relationships, grasping the bigger picture, and understanding how your unique contributions fit into the team.`,
      date: 'Nov 12, 2024',
    },
    {
      image: CreateReactApp,
      href:
        'https://www.freecodecamp.org/news/how-to-migrate-from-create-react-app-to-vite/',
      title: 'How to Migrate from create-react-app to Vite using Jest and Browserslist',
      description: `
      The React team no longer recommends using create-react-app (CRA) as a bundler to create a new React application. The team and community realized that even though CRA was a jump-starter, it lacked the flexibility needed to configure or manage large and complex applications.

      Nowadays, the team recommends using production-grade React frameworks like NextJS, Remix, Gatsby, or Expo for native apps. While frameworks are the preferred choice, the React team also recommend using Vite or Parcel for custom build processes.`,
      date: 'Oct 6, 2023',
    },
  ],
  [
    {
      image: FrontEndOrBackend,
      href:
        'https://www.microverse.org/blog/frontend-or-backend-how-to-know-the-right-track',
      title: 'Frontend Or Backend: How To Know The Right Track For You',
      description: `
      Software development has quickly become a fast-moving train that many people want to get on. However, diving into web development involves learning quickly, in addition to facing a lot of moving parts. One of the most important parts is deciding the career track that suits you best - frontend or backend?
      
      In this article, I’ll take you through what backend and frontend are, as well as highlight the roles and skills needed to thrive as a frontend or backend developer. We’ll conclude with how to determine the perfect track for you. So, let’s get to it!‍`,
      date: 'Oct 7, 2020',
    },
    {
      image: FrontEndImg,
      href:
        'https://www.microverse.org/blog/3-important-frontend-concepts-that-make-you-a-professional',
      title: '3 Important Frontend Concepts that Make You a Professional',
      description: `Frontend development seems to be perceived as the easiest part of software development. People often think it’s all about crafting a beautiful and easily maintained product.

      But what makes you a professional frontend developer?
      
      Likely, you would have heard advice like, “Learn HTML5, CSS3, CSS preprocessors, JavaScript, responsive design, a frontend framework like React/Vue/Angular, and one of the popular automated testing frameworks.”
      
      This article highlights some other important but less common concepts that will make you a better and more professional frontend developer. Let’s dig in!`,
      date: 'SEPT 16, 2020',
    },

  ],
  [
    {
      image: RailsImg,
      href:
        'https://www.freecodecamp.org/news/how-to-set-unique-interchangeable-index-constraint-in-rails/',
      title: 'Rails: How To Set Unique Interchangeable Index Constraint',
      description: `Setting uniqueness validation in rails is something you’ll end up doing quite often. Perhaps, you even already added them to most of your apps. However, this validation only gives a good user interface and experience. It informs the user of the errors preventing the data from being persisted in the database.

      Why uniqueness validation is not enough
      Even with the uniqueness validation, unwanted data sometimes gets saved in the database. For clarity, let’s take a look at a user model shown below:`,
      date: 'JULY 15, 2019',
    },
    {
      image: GitImg,
      href:
        'https://www.freecodecamp.org/news/a-simple-git-guide-and-cheat-sheet-for-open-source-contributors/',
      title: 'A Simple Git Guide And Cheat Sheet For Open Source Contributors',
      description: `A go-to git cheat sheet for your open source contributions.

      If you’re reading this article, you already know that the benefit of open source contribution abounds. You can skip the article and navigate to the end if you’re here for the cheat sheet.
      
      The common problem faced by aspiring open source contributors is how to take the first step from fork to pull request. After reading this article, you should be well equipped with all you need to make your first open source pull request.
      
      Apart from making the process easier for you, the git workflow defined in this piece also makes your contributions look professional. This is especially useful in case you want to add your open source contributions to your portfolio.`,
      date: 'JULY 12, 2019',
    },

  ],
  [
    {
      image: BizImg,
      href:
        'https://medium.com/startup-grind/harsh-truths-no-one-will-tell-you-about-starting-a-business-3acdc700adc',
      title: 'Harsh Truths No One Will Tell You About Starting A Business',
      description: `I wish someone had told me these when I started out. This piece is not to scare you away from starting a business. Rather, it is aimed at prepping you for any possible challenge(s) you may come across along the way. As a result, we’ll break the topic into two facets:
      1. Harsh truths no one will tell you about starting a business.
      2. What you need to create a successful business.
      That said, let’s dive in.`,
      date: 'JUNE 25, 2019',
    },
    {
      image: CssImg,
      href:
        'https://www.freecodecamp.org/news/why-you-should-stop-writing-css-in-css-6fb724f6e3fc/',
      title: 'Why You Should Stop Writing CSS In “CSS”',
      description: `CSS is fun to write, but it can quickly get complicated. A typical example is having to scroll upwards to check the hexadecimal values of the colors you are using. Typing a class or id selector several times within a single CSS file, or having to copy and paste every browser’s support prefix to your code each time for cross-browser compatibility can make your CSS file harder to maintain`,
      date: 'APRIL 8, 2019',
    },
  ]
];

export default ARTICLES;
